// src/PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Sparkles, ArrowLeft } from 'lucide-react';
import privacyPolicyText from './PrivacyPolicy1';

function PrivacyPolicy() {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
            <header className="fixed w-full z-50 bg-gray-900/80 backdrop-blur-lg border-b border-white/10">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16 sm:h-20">
                        <Link to="/" className="flex items-center space-x-2">
                            <div className="relative">
                                <div className="absolute inset-0 bg-blue-500/20 blur-lg rounded-full" />
                                <Sparkles className="relative w-6 h-6 sm:w-8 sm:h-8 text-blue-400" />
                            </div>
                            <span className="text-xl sm:text-3xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                                FitPalAI
                            </span>
                        </Link>
                    </div>
                </div>
            </header>

            <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
                <div className="max-w-4xl mx-auto">
                    <Link
                        to="/"
                        className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors duration-200 mb-8"
                    >
                        <ArrowLeft className="w-5 h-5" />
                        <span>Back to Home</span>
                    </Link>

                    <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-8 shadow-2xl border border-white/10">
                        <h1 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent mb-8">
                            Privacy Policy
                        </h1>

                        <div className="prose prose-lg prose-invert max-w-none">
                            {privacyPolicyText.split('\n\n').map((paragraph, index) => {
                                if (paragraph.startsWith('# ')) {
                                    return (
                                        <h2 key={index} className="text-2xl font-semibold text-gray-200 mt-8 mb-4">
                                            {paragraph.replace('# ', '')}
                                        </h2>
                                    );
                                } else if (paragraph.startsWith('## ')) {
                                    return (
                                        <h3 key={index} className="text-xl font-semibold text-gray-300 mt-6 mb-3">
                                            {paragraph.replace('## ', '')}
                                        </h3>
                                    );
                                } else if (paragraph.trim() === '') {
                                    return <br key={index} />;
                                } else {
                                    return (
                                        <p key={index} className="text-gray-300 mb-4 leading-relaxed">
                                            {paragraph}
                                        </p>
                                    );
                                }
                            })}
                        </div>

                        <div className="mt-8 pt-8 border-t border-white/10">
                            <p className="text-gray-400 text-sm">
                                Last Updated: September 22nd, 2024
                            </p>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-gray-900 border-t border-white/10">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <div className="text-center">
                        <p className="text-gray-400">
                            &copy; {new Date().getFullYear()} FitPalAI. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default PrivacyPolicy;