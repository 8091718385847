import React, {useState, useEffect} from 'react';
import {
    Camera,
    Brain,
    UserSquare2,
    UtensilsCrossed,
    Target,
    Leaf,
    Apple,
    PlaySquare,
    Sparkles,
    ChevronRight,
    MessageCircle,
    LineChart,
    Zap,
    ArrowRight,
    Mail,
    Phone,
    MapPin
} from 'lucide-react';
import {Link} from 'react-router-dom';

import heroImage from './assets/hero.png';
import phoneMockup from './assets/phone-mockup.png';
import Insights from './assets/Insights.png';
import MacrosOverview from './assets/MacrosOverview.png';
import Pal from './assets/Pal.png';

const Feature = ({title, description, icon: Icon}) => (
    <div
        className="group relative overflow-hidden rounded-3xl p-8 transition-all duration-500 hover:-translate-y-2 bg-white/90 backdrop-blur-sm">
        <div
            className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
        <div className="relative z-10">
            <div className="mb-8 flex items-center justify-center">
                <div className="relative">
                    <div className="absolute inset-0 bg-blue-500/20 blur-xl rounded-full"/>
                    <div className="relative p-4 bg-gradient-to-br from-blue-500 to-purple-500 rounded-2xl shadow-xl">
                        <Icon className="w-8 h-8 text-white"/>
                    </div>
                </div>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4 text-center group-hover:text-blue-600 transition-colors duration-300">
                {title}
            </h3>
            <p className="text-lg text-gray-600 text-center leading-relaxed">
                {description}
            </p>
        </div>
    </div>
);

const DownloadButton = ({store, icon: Icon, primary = false, className = ''}) => (
    <button
        className={`group flex items-center space-x-3 ${
            primary
                ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700'
                : 'bg-blue-600 backdrop-blur-sm text-gray-900 border border-gray-200 hover:bg-white'
        } font-semibold py-4 px-6 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 ${className}`}
    >
        <Icon
            className={`w-6 h-6 ${primary ? 'text-white' : 'text-gray-900'} transition-transform duration-300 group-hover:scale-110`}/>
        <span className="hidden sm:inline">Download on {store}</span>
        <span className="inline sm:hidden">{store}</span>
    </button>
);

function Home() {
    const [formData, setFormData] = useState({name: '', email: ''});
    const [status, setStatus] = useState('');
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 20);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Submitting...');
        try {
            const response = await fetch('https://formspree.io/f/mdknqzqb', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setStatus('Thank you for signing up! We will contact you soon.');
                setFormData({name: '', email: ''});
            } else {
                setStatus('Oops! Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setStatus('Oops! Something went wrong. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <header className={`fixed w-full z-50 transition-all duration-300 ${
                isScrolled
                    ? 'bg-black backdrop-blur-lg shadow-lg'
                    : 'bg-transparent'
            }`}>
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16 sm:h-20">
                        <div className="flex items-center space-x-2">
                            <div className="relative">
                                <div className="absolute inset-0 bg-blue-500/20 blur-lg rounded-full"/>
                                <Sparkles className="relative w-6 h-6 sm:w-8 sm:h-8 text-blue-600"/>
                            </div>
                            <h1 className="text-xl sm:text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                                FitPalAI
                            </h1>
                        </div>
                        <nav className="hidden md:flex items-center space-x-8">
                            <a href="#features"
                               className="text-white hover:text-blue-400 transition-colors duration-200 font-medium">Features</a>
                            <a href="#discover"
                               className="text-white hover:text-blue-400 transition-colors duration-200 font-medium">Discover</a>
                            <Link to="/privacy-policy"
                                  className="text-white hover:text-blue-400 transition-colors duration-200 font-medium">Privacy</Link>
                            <a
                                href="#join-beta"
                                className="bg-white/20 backdrop-blur-sm hover:bg-white/30 text-white px-6 py-2 rounded-xl shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all duration-300"
                            >
                                Join Beta
                            </a>
                        </nav>
                        <button className="md:hidden text-white">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6h16M4 12h16m-7 6h7"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
            <section className="relative min-h-screen overflow-hidden" style={{height: 'calc(100vh - 0px)'}}>
                <div className="absolute inset-0">
                    <img
                        src={heroImage}
                        alt="Hero Background"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent"/>
                    <div className="absolute inset-0 bg-white/10 backdrop-blur-[2px]"/>
                </div>

                <div className="relative h-full">
                    <div className="absolute inset-0 flex items-center">
                        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                                <div className="lg:w-1/2 space-y-8 text-center lg:text-left pt-20 lg:pt-0">
                                    <div className="space-y-6">
                                        <div
                                            className="inline-flex items-center space-x-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full border border-white/20">
                                            <Sparkles className="w-5 h-5 text-blue-400"/>
                                            <span className="text-blue-200 font-medium tracking-wide">AI-Powered Nutrition</span>
                                        </div>
                                        <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold leading-tight">
                                            <span
                                                className="text-white drop-shadow-2xl">Transform Your Health With </span>
                                            <span
                                                className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                                                AI Technology
                                            </span>
                                        </h1>
                                        <p className="text-lg sm:text-xl text-gray-300 leading-relaxed max-w-xl mx-auto lg:mx-0 drop-shadow-lg">
                                            Experience the future of nutrition with real-time AI insights
                                            and personalized coaching that adapts to your lifestyle.
                                        </p>
                                    </div>
                                    <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                                        <DownloadButton
                                            store="App Store"
                                            icon={Apple}
                                            primary={true}
                                            className="w-full sm:w-auto"
                                        />
                                        <DownloadButton
                                            store="Google Play"
                                            icon={PlaySquare}
                                            className="w-full sm:w-auto backdrop-blur-md bg-white/10 text-white border-white/20 hover:bg-white/20"
                                        />
                                    </div>
                                </div>

                                <div className="lg:w-1/2 flex justify-center items-center">
                                    <div className="relative w-full max-w-lg xl:max-w-xl">
                                        <div
                                            className="absolute inset-0 bg-gradient-to-tr from-blue-500/30 to-purple-500/30 blur-3xl rounded-full transform translate-y-4"/>
                                        <img
                                            src={phoneMockup}
                                            alt="FitPal App"
                                            className="relative w-full h-100 max-h-[80vh] object-contain transform
                                                     translate-y-0 scale-150 drop-shadow-2xl
                                                     transition-all duration-700 hover:scale-150"
                                        />

                                        {/* Floating Elements */}
                                        <div className="absolute top-1/4 -left-8 bg-white/10 backdrop-blur-lg rounded-2xl p-4 shadow-xl
                                                    animate-float-slow transform hover:scale-105 transition-transform">
                                            <div className="flex items-center space-x-3">
                                                <Brain className="w-8 h-8 text-blue-400"/>
                                                <div className="text-white">
                                                    <div className="text-sm font-medium">AI Analysis</div>
                                                    <div className="text-xs text-blue-200">Real-time insights</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="absolute top-2/3 -right-8 bg-white/10 backdrop-blur-lg rounded-2xl p-4 shadow-xl
                                                    animate-float-delayed transform hover:scale-105 transition-transform">
                                            <div className="flex items-center space-x-3">
                                                <MessageCircle className="w-8 h-8 text-purple-400"/>
                                                <div className="text-white">
                                                    <div className="text-sm font-medium">24/7 Coach</div>
                                                    <div className="text-xs text-purple-200">Always available</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features"
                     className="relative py-32 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-900 via-gray-900 to-gray-800 overflow-hidden">
                {/* Background Effects */}
                <div className="absolute inset-0">
                    <div
                        className="absolute top-1/4 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"/>
                    <div
                        className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-2000"/>
                </div>

                <div className="container mx-auto relative z-10">
                    <div className="text-center mb-20">
                        <h2 className="text-4xl sm:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                            Powered by Advanced AI
                        </h2>
                        <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                            Experience nutrition tracking reimagined with cutting-edge artificial intelligence
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
                        <div
                            className="group relative overflow-hidden rounded-3xl bg-white/5 backdrop-blur-xl p-8 transition-all duration-500 hover:-translate-y-2 border border-white/10">
                            <div
                                className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                            <div className="relative z-10">
                                <div className="mb-8 flex items-center justify-center">
                                    <div className="relative">
                                        <div className="absolute inset-0 bg-blue-500/20 blur-xl rounded-full"/>
                                        <div
                                            className="relative p-4 bg-gradient-to-br from-blue-500 to-purple-500 rounded-2xl shadow-xl">
                                            <Zap className="w-8 h-8 text-white"/>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-white mb-4 text-center group-hover:text-blue-400 transition-colors duration-300">
                                    Real-time Analysis
                                </h3>
                                <p className="text-lg text-gray-300 text-center leading-relaxed">
                                    Get instant nutritional insights with our advanced AI image recognition
                                </p>
                            </div>
                        </div>

                        <div
                            className="group relative overflow-hidden rounded-3xl bg-white/5 backdrop-blur-xl p-8 transition-all duration-500 hover:-translate-y-2 border border-white/10">
                            <div
                                className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                            <div className="relative z-10">
                                <div className="mb-8 flex items-center justify-center">
                                    <div className="relative">
                                        <div className="absolute inset-0 bg-purple-500/20 blur-xl rounded-full"/>
                                        <div
                                            className="relative p-4 bg-gradient-to-br from-purple-500 to-pink-500 rounded-2xl shadow-xl">
                                            <Brain className="w-8 h-8 text-white"/>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-white mb-4 text-center group-hover:text-purple-400 transition-colors duration-300">
                                    Smart Insights
                                </h3>
                                <p className="text-lg text-gray-300 text-center leading-relaxed">
                                    Personalized recommendations based on your goals and progress
                                </p>
                            </div>
                        </div>

                        <div
                            className="group relative overflow-hidden rounded-3xl bg-white/5 backdrop-blur-xl p-8 transition-all duration-500 hover:-translate-y-2 border border-white/10">
                            <div
                                className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                            <div className="relative z-10">
                                <div className="mb-8 flex items-center justify-center">
                                    <div className="relative">
                                        <div className="absolute inset-0 bg-green-500/20 blur-xl rounded-full"/>
                                        <div
                                            className="relative p-4 bg-gradient-to-br from-green-500 to-emerald-500 rounded-2xl shadow-xl">
                                            <MessageCircle className="w-8 h-8 text-white"/>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-white mb-4 text-center group-hover:text-green-400 transition-colors duration-300">
                                    AI Chat Coach
                                </h3>
                                <p className="text-lg text-gray-300 text-center leading-relaxed">
                                    24/7 guidance from your personal nutrition assistant
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="discover" className="py-32 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-800 to-gray-900">
                <div className="container mx-auto">
                    <div className="text-center mb-20">
                        <h2 className="text-4xl sm:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                            Discover FitPal
                        </h2>
                        <p className="text-xl text-gray-300">Revolutionary features for your fitness journey</p>
                    </div>
                    <div className="space-y-40">
                        <div className="flex flex-col lg:flex-row items-center gap-16">
                            <div className="lg:w-1/2 group">
                                <div className="relative">
                                    <div
                                        className="absolute inset-0 bg-gradient-to-tr from-blue-500/30 to-purple-500/30 blur-3xl rounded-3xl group-hover:blur-2xl transition-all duration-500"/>
                                    <img
                                        src={Insights}
                                        alt="Real-time Insights"
                                        className="relative rounded-3xl shadow-2xl transform group-hover:scale-105 transition-transform duration-500"
                                    />
                                    <div
                                        className="absolute inset-0 rounded-3xl bg-gradient-to-tr from-blue-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                                </div>
                            </div>
                            <div className="lg:w-1/2 space-y-6">
                                <div
                                    className="inline-flex items-center space-x-2 bg-blue-500/10 backdrop-blur-sm px-4 py-2 rounded-full border border-blue-500/20">
                                    <LineChart className="w-5 h-5 text-blue-400"/>
                                    <span className="text-blue-400 font-medium">Real-time Analysis</span>
                                </div>
                                <h3 className="text-3xl sm:text-4xl font-bold text-white">
                                    AI-Powered Insights
                                </h3>
                                <p className="text-xl text-gray-300 leading-relaxed">
                                    Get instant nutritional analysis and personalized recommendations.
                                    Our AI processes your meals in real-time, providing detailed insights
                                    about macro and micronutrients.
                                </p>
                                <ul className="space-y-4">
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-blue-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-blue-400"/>
                                        </div>
                                        <span className="text-gray-300">Instant meal analysis with AI vision</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-blue-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-blue-400"/>
                                        </div>
                                        <span className="text-gray-300">Detailed nutrient breakdown</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row-reverse items-center gap-16">
                            <div className="lg:w-1/2 group">
                                <div className="relative">
                                    <div
                                        className="absolute inset-0 bg-gradient-to-tr from-purple-500/30 to-pink-500/30 blur-3xl rounded-3xl group-hover:blur-2xl transition-all duration-500"/>
                                    <img
                                        src={MacrosOverview}
                                        alt="Macros Overview"
                                        className="relative rounded-3xl shadow-2xl transform group-hover:scale-105 transition-transform duration-500"
                                    />
                                    <div
                                        className="absolute inset-0 rounded-3xl bg-gradient-to-tr from-purple-500/10 to-pink-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                                </div>
                            </div>
                            <div className="lg:w-1/2 space-y-6">
                                <div
                                    className="inline-flex items-center space-x-2 bg-purple-500/10 backdrop-blur-sm px-4 py-2 rounded-full border border-purple-500/20">
                                    <Target className="w-5 h-5 text-purple-400"/>
                                    <span className="text-purple-400 font-medium">Progress Tracking</span>
                                </div>
                                <h3 className="text-3xl sm:text-4xl font-bold text-white">
                                    Smart Progress Monitoring
                                </h3>
                                <p className="text-xl text-gray-300 leading-relaxed">
                                    Track your nutrition goals with intuitive visualizations.
                                    Monitor your macros, calories, and overall progress with
                                    our advanced tracking system.
                                </p>
                                <ul className="space-y-4">
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-purple-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-purple-400"/>
                                        </div>
                                        <span className="text-gray-300">Customizable fitness goals</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-purple-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-purple-400"/>
                                        </div>
                                        <span className="text-gray-300">Interactive progress charts</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row items-center gap-16">
                            <div className="lg:w-1/2 group">
                                <div className="relative">
                                    <div
                                        className="absolute inset-0 bg-gradient-to-tr from-green-500/30 to-emerald-500/30 blur-3xl rounded-3xl group-hover:blur-2xl transition-all duration-500"/>
                                    <img
                                        src={Pal}
                                        alt="AI Chat Coach"
                                        className="relative rounded-3xl shadow-2xl transform group-hover:scale-105 transition-transform duration-500"
                                    />
                                    <div
                                        className="absolute inset-0 rounded-3xl bg-gradient-to-tr from-green-500/10 to-emerald-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                                </div>
                            </div>
                            <div className="lg:w-1/2 space-y-6">
                                <div
                                    className="inline-flex items-center space-x-2 bg-green-500/10 backdrop-blur-sm px-4 py-2 rounded-full border border-green-500/20">
                                    <MessageCircle className="w-5 h-5 text-green-400"/>
                                    <span className="text-green-400 font-medium">24/7 Support</span>
                                </div>
                                <h3 className="text-3xl sm:text-4xl font-bold text-white">
                                    Your Personal AI Coach
                                </h3>
                                <p className="text-xl text-gray-300 leading-relaxed">
                                    Get personalized nutrition advice anytime. Our AI coach provides
                                    instant answers to your questions, meal suggestions, and
                                    motivation to keep you on track.
                                </p>
                                <ul className="space-y-4">
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-green-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-green-400"/>
                                        </div>
                                        <span className="text-gray-300">24/7 AI nutritionist access</span>
                                    </li>
                                    <li className="flex items-center space-x-3">
                                        <div
                                            className="flex-shrink-0 w-6 h-6 bg-green-500/20 rounded-full flex items-center justify-center">
                                            <ChevronRight className="w-4 h-4 text-green-400"/>
                                        </div>
                                        <span className="text-gray-300">Personalized meal planning</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="join-beta" className="py-32 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-900 to-gray-800 relative overflow-hidden">
                <div className="absolute inset-0">
                    <div className="absolute top-1/2 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob" />
                    <div className="absolute top-1/2 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-2000" />
                </div>
                <div className="container mx-auto max-w-xl relative z-10">
                    <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-8 shadow-2xl border border-white/20">
                        <div className="text-center mb-12 space-y-4">
                            <h2 className="text-4xl sm:text-5xl font-bold">
                                <span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                                    Join Our Beta Program
                                </span>
                            </h2>
                            <p className="text-xl text-gray-300">
                                Be among the first to experience the future of fitness tracking
                            </p>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="space-y-4">
                                <div className="relative group">
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Your Name"
                                        required
                                        className="w-full px-6 py-4 bg-white/5 rounded-xl border border-white/10 text-white placeholder-gray-400
                                                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                                 transition-all duration-300 group-hover:bg-white/10"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100
                                                  rounded-xl blur-xl transition-opacity duration-300" />
                                </div>
                                <div className="relative group">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Your Email"
                                        required
                                        className="w-full px-6 py-4 bg-white/5 rounded-xl border border-white/10 text-white placeholder-gray-400
                                                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                                 transition-all duration-300 group-hover:bg-white/10"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 opacity-0 group-hover:opacity-100
                                                  rounded-xl blur-xl transition-opacity duration-300" />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold py-4 px-6 rounded-xl
                                         shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 hover:from-blue-600
                                         hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                                         focus:ring-offset-gray-900"
                            >
                                Join Beta Testing
                            </button>
                            {status && (
                                <div className="text-center animate-fade-in">
                                    <p className={`text-lg ${
                                        status.includes('Thank you')
                                            ? 'text-green-400'
                                            : 'text-blue-400'
                                    }`}>
                                        {status}
                                    </p>
                                </div>
                            )}
                        </form>

                        <div className="mt-12 pt-8 border-t border-white/10">
                            <div className="flex flex-col items-center space-y-6 text-center">
                                <p className="text-gray-300">
                                    Already excited? Download our latest version
                                </p>
                                <div className="flex flex-wrap justify-center gap-4">
                                    <DownloadButton
                                        store="App Store"
                                        icon={Apple}
                                        primary={true}
                                    />
                                    <DownloadButton
                                        store="Google Play"
                                        icon={PlaySquare}
                                        className="bg-white/10 text-white border-white/20 hover:bg-white/20"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="bg-gray-900 border-t border-white/10">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                        <div className="space-y-4 md:col-span-2">
                            <div className="flex items-center space-x-2">
                                <div className="relative">
                                    <div className="absolute inset-0 bg-blue-500/20 blur-lg rounded-full" />
                                    <Sparkles className="relative w-8 h-8 text-blue-400" />
                                </div>
                                <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                                    FitPalAI
                                </h1>
                            </div>
                            <p className="text-gray-400 max-w-sm">
                                Transform your health journey with AI-powered nutrition tracking
                                and personalized insights.
                            </p>
                            <div className="flex flex-wrap gap-4">
                                <DownloadButton
                                    store="App Store"
                                    icon={Apple}
                                    className="bg-white/10 text-white border-white/20 hover:bg-white/20"
                                />
                                <DownloadButton
                                    store="Google Play"
                                    icon={PlaySquare}
                                    className="bg-white/10 text-white border-white/20 hover:bg-white/20"
                                />
                            </div>
                        </div>

                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-white">Quick Links</h3>
                            <nav className="flex flex-col space-y-2">
                                <a href="#features" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">Features</a>
                                <a href="#discover" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">Discover</a>
                                <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">Privacy Policy</Link>
                                <a href="#join-beta" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">Beta Program</a>
                            </nav>
                        </div>
                    </div>

                    <div className="mt-12 pt-8 border-t border-white/10 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                        <p className="text-gray-400">&copy; {new Date().getFullYear()} FitPalAI. All rights reserved.</p>
                        <div className="flex space-x-6">
                            <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Home;