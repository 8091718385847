// privacyPolicy.js

const privacyPolicyText = `
Last Updated: 22nd September 2024

This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the FitPal mobile application ("Service") and tells You about Your privacy rights and how the law protects You.

By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.

Interpretation and Definitions
Interpretation
The words with initial capital letters have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.

Definitions
For the purposes of this Privacy Policy:

Account means a unique account created for You to access our Service or parts of our Service.
Application refers to the FitPal software program provided by the Company.
Company (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to FitPal.
Country refers to [Your Country].
Device means any device that can access the Service such as a computer, a cellphone, or a digital tablet.
Personal Data is any information that relates to an identified or identifiable individual.
Service refers to the Application.
Service Provider means any natural or legal person who processes the data on behalf of the Company.
Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.
You means the individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service.
Collecting and Using Your Personal Data
Types of Data Collected
Personal Data

While using Our Service, We may ask You to provide Us with certain personally identifiable information, including but not limited to:

Email address
First name and last name
Health and fitness data (e.g., fitness goals, dietary preferences)
Images you upload for meal analysis
Usage Data
Usage Data

Usage Data is collected automatically when using the Service. It may include information such as Your Device's Internet Protocol address (IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, and other diagnostic data.

When You access the Service by or through a mobile device, We may collect certain information automatically, including but not limited to the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, and other diagnostic data.

Use of Your Personal Data
The Company may use Personal Data for the following purposes:

To provide and maintain our Service, including to monitor the usage of our Service.
To manage Your Account: to manage Your registration as a user of the Service.
To personalize Your experience: to provide personalized nutritional recommendations, meal suggestions, and fitness guidance.
To facilitate interactive features: to enable You to use interactive features of the Service when You choose to do so.
To provide customer support: to address and manage Your requests.
To send You communications: to contact You by email or other equivalent forms of electronic communication regarding updates or informative communications related to functionalities or services.
For business transfers: to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets.
For other purposes: such as data analysis, identifying usage trends, and to improve our Service and user experience.
Sharing of Your Personal Data
We may share Your personal information in the following situations:

With Service Providers: to monitor and analyze the use of our Service and to contact You.
For business transfers: in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business.
With affiliates: We may share Your information with Our affiliates, in which case we will require them to honor this Privacy Policy.
With business partners: to offer You certain products, services, or promotions.
With other users: when You share personal information or interact in public areas with other users.
With Your consent: We may disclose Your personal information for any other purpose with Your consent.
We do not sell Your personal information to third parties.

Retention of Your Personal Data
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.

Transfer of Your Personal Data
Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. This means that Your information may be transferred to and maintained on computers located outside of Your state, province, country, or other governmental jurisdiction.

Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.

Security of Your Personal Data
The security of Your Personal Data is important to Us, but no method of transmission over the Internet or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.

Children's Privacy
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under 13 years of age. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us.

Links to Other Websites
Our Service may contain links to third-party websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

Changes to This Privacy Policy
We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.

You are advised to review this Privacy Policy periodically for any changes.

Contact Us
If you have any questions about this Privacy Policy, You can contact us:

By email: support@fitpalai.fit
By using FitPal, You acknowledge that You have read and understood this Privacy Policy and agree to its terms.
`;

export default privacyPolicyText;
